.sport-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  transition: transform 0.3s ease;
  background-color: #fff;
  margin: 10px;
  text-align: center;
  width: 220px;
}

.sport-card:hover {
  transform: translateY(-5px);
}

.sport-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.sport-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sport-name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
}
