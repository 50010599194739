.sports-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.sports-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.sports-page h1 {
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

.sports-page p {
  font-size: 18px;
  color: #666;
}
