
.card {
    position: relative;
    width: 190px;
    height: 254px;
    background-color: #171515;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    margin: 60px;
  }
  
.card::before {
  content: '';
  position: absolute;
  inset: 0;
  left: -5px;
  margin: auto;
  width: 200px;
  height: 264px;
  border-radius: 10px;
  background: linear-gradient(-45deg, #b32c26 0%, #2a0606 100% );
  z-index: -10;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
}

.card::after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(-45deg, #b32c26 0%, #2a0606 100% );
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
  
  
}

.heading {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
}

.card p:not(.heading) {
  font-size: 8px;
}

.card p:last-child {
  color: white;
  font-weight: 600;
  
}

.card:hover::after {
  filter: blur(30px);
}

.card:hover::before {
  transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
}


.zoom-on-hover {
  transition: transform 0.2s ease-out;
  
}

.zoom-on-hover:hover {
  transform: scale(2);
  z-index: 999;
}

.zoom-in-hover {
  transition: transform 0.2s ease-out;
  
}

.zoom-in-hover:hover {
  transform: scale(1.1);
  z-index: 1;
}
p {
  white-space: pre-wrap;
}

.pp {
  width: 180px;
  height: auto;
}