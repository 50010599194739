.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .nav-link {
    text-decoration: none;
    padding: 10px;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  
  .dropdown-item {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: black;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  