.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.popup-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.popup-details {
  width: 100%;
}

.popup-details h1, .popup-details h2 {
  color: #333;
  margin-bottom: 10px;
}

.popup-details p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 15px;
}
